import React from "react"
import Logo from "../inline-svg/logo.svg"
import MenuLinks from "../components/partials/menu-links"
import QuoteForm from "../components/partials/quote-form"

const Footer = () => (
    <div id="footer" class="footer">
        <div class="section-container">
            <div class="section-dark curve-top gradient-blue-purple">
                <div class="footer-main">
                    <div class="container">
                        <div class="row">
                            <div class="col col-sm-12">
                                <div class="footer-logo">
                                    <Logo/>
                                </div>
                                <div class="footer-links">
                                    <MenuLinks />
                                </div>
                                <div class="footer-form">
                                    <h3>Check your personal rate</h3>
                                    <QuoteForm/>
                                </div>
                                <div class="footer-content">
                                    <p>
                                        Loaned and Flux Funding Lts take your privacy seriously.<br/>
                                        Please see the <a href="">Loaned Privacy Policy</a> and <a href="">Flux Funding
                                        Privacy Policy</a> for more details.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default Footer
