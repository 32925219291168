import React from "react"
import {graphql, useStaticQuery, Link} from "gatsby";

const MenuLinks = () => {

    const data = useStaticQuery(graphql`
        query {
            content: dataJson {
                global {
                    menu_links {                        
                        title,
                        link                                                                     
                    }
                }
            }
        }
    `)

    return (
        <ul>
            {data.content.global.menu_links.map(object => (
                <li><Link to={object.link} dangerouslySetInnerHTML={{__html: object.title}}/></li>
            ))}
        </ul>
    )
}

export default MenuLinks