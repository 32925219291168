import React from "react"

import {Link} from "gatsby"
import Logo from "../inline-svg/logo.svg"

class Header extends React.Component {

    render() {
        return (
            <div id="header">
                <div class="gradient-blue-purple header curve-bottom">
                    <div class="container">
                        <div class="row">
                            <div class="col col-xs-12 col-sm-6 col-sm-offset-3 header-center">
                                <Link to="/" className="logo">
                                    <Logo/>
                                </Link>
                            </div>
                            <div class="col col-xs-12 col-sm-3 header-right">
                                <Link to="/" className="btn btn-menu" onClick={this.props.toggleMenu}>
                                    <span class="bar bar-1" />
                                    <span class="bar bar-2" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Header