import React from "react"
import ReactImg from "gatsby-image"

const Image = ({img, alt, className}) => {
    if(!img){
        return ''
    }
    if(img.childImageSharp){
        if(img.childImageSharp.fluid){
            return <ReactImg fluid={img.childImageSharp.fluid} alt={alt} className={className} />
        } else{
            return <ReactImg fixed={img.childImageSharp.fixed} alt={alt} className={className} />
        }
    }
    let url = img
    if(img.publicURL){
        url = img.publicURL
    }
    return <img src={url} alt={alt} className={className} />
}

export default Image