import React from "react";
import {graphql, useStaticQuery} from "gatsby";

const AprText = () => {

    const query = useStaticQuery(graphql`
        query {
            wordpressAcfOptions {
                options { 
                    personalised_apr_text
                }             
            }               
        }
    `)

    return (
        <p className="personalised-apr" dangerouslySetInnerHTML={{
            __html: query.wordpressAcfOptions.options.personalised_apr_text
        }}/>
    )
}

export default AprText