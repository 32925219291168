import React from "react"
import Header from "../components/header"
import Menu from "../components/menu"
import Footer from "../components/footer"
import {Helmet} from "react-helmet/es/Helmet";

class Layout extends React.Component {

    menuClasses = [
        'modal-open',
        'menu-open'
    ]

    constructor(props) {
        super(props)
        this.state = {menuOpen: false, isClient: false}
    }

    componentDidMount() {
        this.tidyBodyClasses()
        if (this.props.reRender) {
            this.setState({isClient: true})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.tidyBodyClasses()
        if (this.state.menuOpen && this.props.location.key !== prevProps.location.key) {
            this.closeMenu()
        }
    }

    tidyBodyClasses() {
        this.menuClasses.forEach((className) => {
            document.body.classList.toggle(className, this.state.menuOpen)
        })
    }

    toggleMenu(e) {
        e.preventDefault()
        if (!this.state.menuOpen) {
            this.openMenu()
        } else {
            this.closeMenu()
        }
    }

    openMenu() {
        this.setState({menuOpen: true})
    }

    closeMenu() {
        this.setState({menuOpen: false})
    }

    render() {
        return (
            <React.Fragment key={this.state.isClient}>
                <Header toggleMenu={this.toggleMenu.bind(this)}/>
                <Menu menuOpen={this.state.menuOpen} toggleMenu={this.toggleMenu.bind(this)}/>
                {this.props.children}
                <Footer/>
            </React.Fragment>
        )
    }

}

export default Layout