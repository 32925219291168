import React from "react"
import classNames from "classnames"

import MenuLinks from "../components/partials/menu-links"
import QuoteForm from "../components/partials/quote-form";

class Menu extends React.Component{

    render(){
        return (
            <div className={classNames('section-dark', 'main-menu', {'open' : this.props.menuOpen})}>
                <div className="menu-logo">
                    
                </div>
                <a href="" className="btn btn-menu open" onClick={this.props.toggleMenu}>
                    <span className="bar bar-1"></span><span className="bar bar-2"></span>
                </a>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col col-sm-6">
                            <div className="nav">
                                <MenuLinks />
                            </div>
                        </div>
                        <div className="col col-sm-6">
                            <div className="menu-rate-check">
                                <a href="" className="btn btn-default btn-mobile-rate">Check my rate</a>                               
                            </div>
                            <div className="nav-form">
                                <h5>Check your personal rate</h5>
                                <QuoteForm />
                            </div>
                            <a href="" className="btn-mobile-rate btn-rate-close"><i className="far fa-arrow-left"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }        
}

export default Menu