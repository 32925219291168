
import React from "react";

const withScreenSize  = WrappedComponent => {
    return class extends React.Component {
        constructor(props) {
            super(props)
            this.state = {windowWidth: 0, windowHeight: 0}
        }
        componentDidMount() {
            this.onWindowResize()
            window.addEventListener('resize', this.onWindowResize.bind(this))
        }
        onWindowResize() {
            this.setState({
                windowWidth: window.innerWidth,
                windowHeight: window.innerHeight
            })
        }
        render() {
            return <WrappedComponent windowWidth={this.state.windowWidth} windowHeight={this.state.windowHeight} {...this.props}/>
        }
    }
}

export default withScreenSize