import React from "react"
import {graphql, useStaticQuery} from "gatsby";
import WPImage from "../elements/wp-image";
import Slider from "react-slick";
import withScreenSize from "../helpers/withScreenSize";


const UspsComponent = (props) => {
    const query = useStaticQuery(graphql`
        query {
            wordpressAcfOptions {
                options { 
                    usps {
                        usp
                        image {
                            ...WordPressImage
                        }
                    }
                }             
            }    
        }
    `)
    const isSlider = props.slider && props.windowWidth > 480;
    return (
            <>
                {!isSlider ? (
                    <div className="usps">
                        {query.wordpressAcfOptions.options.usps && query.wordpressAcfOptions.options.usps.map(object => (
                            <div className="usp">
                                <WPImage img={object.image}/>
                                {object.usp}
                            </div>
                        ))}
                    </div>
                ) : (
                    <Slider className="usps" {...{
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        speed: 1000,
                        arrows: false,
                        dots: false,
                        autoplay: true,
                        autoplaySpeed: 2500,
                        draggable: false
                    }}>
                        {query.wordpressAcfOptions.options.usps && query.wordpressAcfOptions.options.usps.map(object => (
                            <div className="usp">
                                <WPImage img={object.image}/>
                                {object.usp}
                            </div>
                        ))}
                    </Slider>
                )}
            </>
        )
}

const Usps = withScreenSize(UspsComponent)

export default Usps