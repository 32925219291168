import React from "react"
import {navigate} from "gatsby"

class QuoteForm extends React.Component {
    startingLoanAmount = 1500
    startingMonths = 12

    constructor(props) {
        super(props)
        this.state = {
            loanAmount: this.props.loanAmount ? this.props.loanAmount
                : this.startingLoanAmount,
            months: this.props.months ? this.props.months
                : this.startingMonths
        }
    }

    handleSubmit(e) {
        e.preventDefault()
        navigate('/search/', {
            state: this.state
        })
    }

    formatLoanAmount() {
        return '£' + this.state.loanAmount
    }

    increaseLoanAmount(e) {
        e.preventDefault()
        this.setState(prevState => ({
            loanAmount: prevState.loanAmount + 1
        }))
    }

    decreaseLoanAmount(e) {
        e.preventDefault()
        this.setState((prevState, props) => ({
            loanAmount: prevState.loanAmount - 1
        }))
    }

    formatMonths() {
        let suffix
        if (this.state.months === 1) {
            suffix = 'Month'
        } else {
            suffix = 'Months'
        }
        return this.state.months + ' ' + suffix
    }

    increaseMonths(e) {
        e.preventDefault()
        this.setState((prevState, props) => ({
            months: prevState.months + 1
        }))
    }

    decreaseMonths(e) {
        e.preventDefault()
        this.setState((prevState, props) => ({
            months: prevState.months - 1
        }))
    }

    render() {

        return (
            <div className="quote-form">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="loan-details">
                        <div className="loan-amount">
                            <div className="form-group">
                                <label>Amount</label>
                                <div className="loan-amount-input">
                                    <input type="text" className="form-control loan-amount-input"
                                           value={this.formatLoanAmount()}/>
                                    <a href="" className="btn btn-blue amount-change price-change amount-minus"
                                       onClick={this.decreaseLoanAmount.bind(this)}>
                                        <i className="fas fa-minus"></i>
                                    </a>
                                    <a href="" className="btn btn-blue amount-change price-change amount-plus"
                                       onClick={this.increaseLoanAmount.bind(this)}>
                                        <i className="fas fa-plus"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="loan-amount loan-months">
                            <div className="form-group">
                                <label>For</label>
                                <div className="loan-amount-input">
                                    <input type="text" className="form-control loan-months-input"
                                           value={this.formatMonths()}/>
                                    <a href="#" className="btn btn-blue amount-change month-change amount-minus"
                                       onClick={this.decreaseMonths.bind(this)}>
                                        <i className="fas fa-minus"></i>
                                    </a>
                                    <a href="#" className="btn btn-blue amount-change month-change amount-plus"
                                       onClick={this.increaseMonths.bind(this)}>
                                        <i className="fas fa-plus"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="btn btn-default">Free Rate Check</button>
                </form>
            </div>
        )
    }
}

export default QuoteForm