import React from "react"
import Image from "./image"

const WPImage = ({img, alt, className}) => {
    if ((!img) || !img.localFile) {
        return ''
    }
    return <Image img={img.localFile} alt={alt ? alt : img.alt_text} className={className}/>
}

export default WPImage